import React from 'react'
import CreateCheckout from './createCheckout'

const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function CEOfferItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return <CreateCheckout {...props} id={id} />
}

export default CEOfferItem;